import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { getUser } from '@xtream/firebase-ngrx-user-management';
import * as daysjs from 'dayjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { LessonDTO } from '../../commons/models/lesson';
import { ToastService } from '../../commons/services/toast.service';
import * as CalendarActions from '../actions/calendar.actions';
import * as LessonActions from '../actions/lesson.actions';
import { AppState } from '../reducers';
import { getCurrentRange } from './../selectors/calendar.selectors';

@Injectable()
export class CalendarEffects {

    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.loadLessonsFailed),
            tap(({ error }) => {
                if (error) {
                    this.toastService.showError('Error', error);
                }
            })
        ), { dispatch: false }
    );

    loadLessons$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.loadLessons),
            withLatestFrom(this.store$.pipe(select(getUser))),
            tap(() => this.ngxService.start()),
            switchMap(([{ start, end }, user]) => {
                return this.db.collection<LessonDTO>('lessons', (ref) => {
                    let query: Query = ref
                    if (start) {
                        query = query.where('start', '>=', new Date(start));
                    }
                    if (end) {
                        query = query.where('start', '<=', new Date(end));
                    }
                    query = query.where('uid', '==', user.uid);
                    return query;
                }).get()
            }),
            map(snapshot => {
                if (snapshot.empty) {
                    return [];
                }
                return <LessonDTO[]>snapshot.docs.map(doc => Object.assign({}, doc.data(), { id: doc.id }));
            }),
            map(lessons => {
                return CalendarActions.loadLessonsCompleted({ lessons })
            })
        )
    );

    reloadOnDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.deleteLessonCompleted),
            tap(() => console.log('Reload on delete')),
            switchMap(() => this.store$.pipe(select(getCurrentRange), take(1))),
            map(({ startDate, endDate }) => CalendarActions.loadLessons({ start: startDate, end: endDate }))
        )
    );

    intervalChanged$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.intervalChanged),
            tap(() => console.log('Interval changed')),
            map(({ startDate, endDate }) => CalendarActions.loadLessons({ start: startDate, end: endDate }))
        )
    );

    changeMonth$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.changeMonth),
            tap(() => console.log('Month changed')),
            map(({ month }) => {
                const start = daysjs(month).startOf('month').toDate();
                const end = daysjs(month).endOf('month').toDate();
                return CalendarActions.loadLessons({ start, end })
            })
        )
    );

    resetSelection$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.changeMonth, CalendarActions.intervalChanged, CalendarActions.changeMonth),
            map(() => CalendarActions.selectDay({ day: null }))
        )
    )

    loadCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CalendarActions.loadLessonsCompleted, CalendarActions.loadLessonsFailed),
            tap(() => this.ngxService.stop())
        ), { dispatch: false }
    )

    constructor(
        private actions$: Actions,
        private toastService: ToastService,
        private store$: Store<AppState>,
        private db: AngularFirestore,
        private ngxService: NgxUiLoaderService
    ) { }
}