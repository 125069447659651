import { createAction, props } from '@ngrx/store';
import { Asana, Prana } from 'src/app/commons/models/asana';

import { LessonDTO } from '../../commons/models/lesson';

export const addLesson = createAction('[Lessons] Add lesson', props<{ date: Date }>());
export const showLesson = createAction('[Lessons] Show lesson', props<{ lesson: LessonDTO }>());
export const editLesson = createAction('[Lessons] Edit lesson', props<{ lesson: LessonDTO }>());

export const shareLesson = createAction('[Lessons] Share lesson', props<{ lesson: LessonDTO }>());

export const saveLesson = createAction('[Lessons] Save lesson', props<{ lesson: LessonDTO }>());
export const saveLessonCompleted = createAction('[Lessons] Save lesson Completed', props<{ lesson: LessonDTO }>());
export const saveLessonFailed = createAction('[Lessons] Save lesson Failed', props<{ error: any }>());

export const loadLesson = createAction('[Lessons] Load lesson', props<{ id: string }>());
export const loadLessonCompleted = createAction('[Lessons] Load lesson Completed', props<{ lesson: LessonDTO }>());
export const loadLessonFailed = createAction('[Lessons] Load lesson Failed', props<{ error: any }>());

export const deleteLesson = createAction('[Lessons] Delete lesson', props<{ lesson: LessonDTO }>());
export const deleteLessonConfirmed = createAction('[Lessons] Delete lesson confirmed', props<{ lesson: LessonDTO }>());
export const deleteLessonCompleted = createAction('[Lessons] Delete lesson completed', props<{ lesson: LessonDTO }>());
export const deleteLessonCancelled = createAction('[Lessons] Delete lesson cancelled');
export const deleteLessonFailed = createAction('[Lessons] Delete lesson failed', props<{ error: any }>());

export const selectNewStep = createAction('[Lesson] Select new Step');
export const closeStepSelector = createAction('[Lesson] Close Step selector');
export const addStep = createAction('[Lesson] Add Step', props<{ step: Asana | Prana }>());
// export const removeStep = createAction('[Lesson] Remove Step', props<{ step: Asana | Prana }>())

// export const moveStep = createAction('[Lesson] Move Step', props<{ step: Asana | Prana, newIndex: number }>());
