import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Asana, Prana } from 'src/app/commons/models/asana';
import { addStep, closeStepSelector } from 'src/app/store/actions/lesson.actions';
import { loadAsanas, loadPranas } from 'src/app/store/actions/step.actions';
import { AppState } from 'src/app/store/reducers';
import { getAsanas, getPranas } from 'src/app/store/selectors/step.selectors';

@Component({
  selector: 'app-step-selector',
  templateUrl: './step-selector.component.html',
  styleUrls: ['./step-selector.component.scss'],
})
export class StepSelectorComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  selectedType: "asana" | "prana" = "asana";

  private _asanasSubject$: BehaviorSubject<Asana[]> = new BehaviorSubject<Asana[]>([]);
  private _pranasSubject$: BehaviorSubject<Prana[]> = new BehaviorSubject<Prana[]>([]);

  pranas: Prana[];
  asanas: Asana[];

  searchControl = new FormControl();

  constructor(private store$: Store<AppState>) {
    this.store$.pipe(select(getAsanas), takeUntil(this.unsubscribe$)).subscribe(asanas => this._asanasSubject$.next(asanas));
    this.store$.pipe(select(getPranas), takeUntil(this.unsubscribe$)).subscribe(pranas => this._pranasSubject$.next(pranas));

    combineLatest(this._asanasSubject$, this.searchControl.valueChanges.pipe(startWith(""))).pipe(map(([asanas, filter]) => {
      return asanas ? asanas.filter(asana => {
        return filter ? asana.name.toLowerCase().includes(filter.toLowerCase()) : true;
      }) : []
    })).subscribe(asanas => {
      this.asanas = asanas
    })

    combineLatest(this._pranasSubject$, this.searchControl.valueChanges.pipe(startWith(""))).pipe(map(([pranas, filter]) => {
      return pranas ? pranas.filter(prana => {
        return filter ? prana.name.toLowerCase().includes(filter.toLowerCase()) : true;
      }) : []
    })).subscribe(pranas => {
      this.pranas = pranas
    })
  }

  ngOnInit() {
    this.store$.dispatch(loadAsanas());
    this.store$.dispatch(loadPranas());
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  typeChanged(event: CustomEvent) {
    this.selectedType = event.detail.value;
  }

  done() {
    this.store$.dispatch(closeStepSelector());
  }

  addStep(step: Asana | Prana) {
    this.store$.dispatch(addStep({ step }));
  }

  showAsanaDetails(asana: Asana) {
    //TODO Show Asana Details
  }

  showPranaDetails(prana: Prana) {
    //TODO Show Prana Details
  }

}
