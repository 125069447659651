import { createAction, props } from '@ngrx/store';

import { Asana, Prana } from '../../commons/models/asana';

export const loadAsanas = createAction('[Steps] Load asanas');
export const loadAsanasCompleted = createAction('[Steps] Load asanas Completed', props<{ asanas: Asana[] }>());
export const loadAsanasFailed = createAction('[Steps] asanas Failed', props<{ error: any }>());

export const loadPranas = createAction('[Steps] Load pranas');
export const loadPranasCompleted = createAction('[Steps] Load pranas Completed', props<{ pranas: Prana[] }>());
export const loadPranasFailed = createAction('[Steps] pranas Failed', props<{ error: any }>());