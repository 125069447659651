import { createSelector } from '@ngrx/store';

import { selectStepState } from '../reducers';
import { StepState } from '../reducers/step.reducer';

export const getAsanas = createSelector(
    selectStepState,
    (state: StepState) => state.asanas
)

export const getPranas = createSelector(
    selectStepState,
    (state: StepState) => state.pranas
)

