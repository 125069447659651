import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: "root"
})
export class AlertService {
    constructor(private alertController: AlertController, private translateService: TranslateService) { }

    showAlert(title: string, message: string, confirmLabel: string = "ok"): Observable<boolean> {
        return this.doShow(title, message, confirmLabel, null);
    }

    showConfirm(title: string, message: string, confirmLabel: string = "ok", cancelLabel: string = "cancel"): Observable<boolean> {
        return this.doShow(title, message, confirmLabel, cancelLabel);
    }

    private doShow(title: string, message: string, confirmLabel: string, cancelLabel: string): Observable<boolean> {
        let buttons = [];
        if (confirmLabel) {
            buttons.push({ text: this.translateService.instant(confirmLabel), role: "ok" });
        }
        if (cancelLabel) {
            buttons.push({ text: this.translateService.instant(cancelLabel), role: "cancel" });
        }

        return from(
            this.alertController.create({
                header: this.translateService.instant(title),
                message: this.translateService.instant(message),
                buttons: buttons
            })
        ).pipe(
            tap(alert => alert.present()),
            flatMap(alert => {
                return from(alert.onDidDismiss()).pipe(map(result => result.role === "ok"));
            })
        );
    }
}
