import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Asana, Prana } from '../../commons/models/asana';
import { ToastService } from '../../commons/services/toast.service';
import * as StepActions from '../actions/step.actions';

@Injectable()
export class StepEffects {

    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StepActions.loadAsanasFailed, StepActions.loadPranasFailed),
            tap(({ error }) => {
                if (error) {
                    this.toastService.showError('Error', error);
                }
            })
        ), { dispatch: false }
    );

    loadAsanas$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StepActions.loadAsanas),
            switchMap(() => {
                return this.httpClient.get<Asana[]>('assets/data/asanas.json')
                    .pipe(
                        map(asanas => asanas.sort((a1, a2) => a2.name > a1.name ? -1 : 1)),
                        map(asanas =>
                            StepActions.loadAsanasCompleted({ asanas })
                        ),
                        catchError(error => {
                            return of(StepActions.loadAsanasFailed({ error }))
                        })
                    )
            })
        )
    );

    loadPranas$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StepActions.loadPranas),
            switchMap(() => {
                return this.httpClient.get<Prana[]>('assets/data/pranas.json')
                    .pipe(
                        map(pranas => pranas.sort((p1, p2) => p2.name > p1.name ? -1 : 1)),
                        map(pranas =>
                            StepActions.loadPranasCompleted({ pranas })
                        ),
                        catchError(error => {
                            return of(StepActions.loadPranasFailed({ error }))
                        })
                    )
            })
        )
    );

    constructor(
        private actions$: Actions,
        private toastService: ToastService,
        private httpClient: HttpClient
    ) { }
}