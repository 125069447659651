import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import * as LessonActions from 'src/app/store/actions/lesson.actions';
import { AppState } from 'src/app/store/reducers';
import { getSelectedLesson } from 'src/app/store/selectors/lesson.selectors';
import { selectRouteId } from 'src/app/store/selectors/route.selectors';

import { LessonDTO } from './../models/lesson';

@Injectable()
export class LessonResolver implements Resolve<LessonDTO> {
    constructor(private store: Store<AppState>) { }

    resolve(): Observable<LessonDTO> {
        return this.store.pipe(
            select(selectRouteId),
            filter(id => !!id),
            tap(id => this.store.dispatch(LessonActions.loadLesson({ id }))),
            switchMap(() => this.store.pipe(
                select(getSelectedLesson),
                filter(lesson => !!lesson),
                take(1)
            )),
            take(1)
        )
    }
}