import { createAction, props } from '@ngrx/store';

import { LessonDTO } from '../../commons/models/lesson';

export const loadLessons = createAction('[Lessons] Load lessons', props<{ start: Date, end: Date }>());
export const loadLessonsCompleted = createAction('[Lessons] Load lessons Completed', props<{ lessons: LessonDTO[] }>());
export const loadLessonsFailed = createAction('[Lessons] Load lessons Failed', props<{ error: any }>());

export const changeMonth = createAction('[Lessons] Change month', props<{ month: Date }>());
export const intervalChanged = createAction('[Lessons] Interval changed', props<{ startDate: Date, endDate: Date }>());

export const selectDay = createAction('[Lessons] Select day', props<{ day: Date }>());
