import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from '../actions/auth.actions';

export interface AuthState {
    playerID: string,
    redirectURL: string
};

const initialState: AuthState = {
    redirectURL: null,
    playerID: null,
};

const authReducer = createReducer(
    initialState,
    on(AuthActions.setRedirectURL, (state, { url }) => {
        return { ...state, redirectURL: url }
    }),
    on(AuthActions.setPlayerID, (state, { playerID }) => {
        return { ...state, playerID }
    }),
);

export function reducer(state: AuthState | undefined, action: Action) {
    return authReducer(state, action);
}
