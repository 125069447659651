import { Action, createReducer, on } from '@ngrx/store';

import { LessonDTO } from '../../commons/models/lesson';
import * as LessonActions from '../actions/lesson.actions';

export interface LessonState {
    lesson: LessonDTO;
};

const initialState: LessonState = {
    lesson: null,
};

const lessonState = createReducer(
    initialState,
    on(LessonActions.loadLessonCompleted, (state, { lesson }) => {
        return { ...state, lesson };
    }),
    on(LessonActions.editLesson, (state, { lesson }) => {
        return { ...state, lesson };
    }),
    on(LessonActions.showLesson, (state, { lesson }) => {
        return { ...state, lesson };
    }),
    on(LessonActions.saveLessonCompleted, (state, { lesson }) => {
        return { ...state, lesson };
    })
);

export function reducer(state: LessonState | undefined, action: Action) {
    return lessonState(state, action);
}