import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthActions, PasswordManagementActions } from '@xtream/firebase-ngrx-user-management';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/commons/services/alert.service';

import { ToastService } from '../../commons/services/toast.service';
import * as MyAuthActions from '../actions/auth.actions';
import * as RouterActions from '../actions/router.actions';
import { AppState } from '../reducers';

@Injectable()
export class AuthEffects {

    loginCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.AuthActionTypes.Authenticated, AuthActions.AuthActionTypes.RegistrationSuccess),
            map(() => RouterActions.routerGo({ path: ['/home'] }))
        )
    );


    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MyAuthActions.tryLogout),
            switchMap(() => this.alertService.showConfirm('Confirm Exit', 'Are you sure you want to exit?')),
            switchMap((confirm) => {
                return confirm ? [new AuthActions.Logout(), RouterActions.routerGo({ path: ['/login'] })] : of(MyAuthActions.logoutCancelled());
            })
        )
    );

    onRegisterCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType<AuthActions.RegistrationSuccess>(AuthActions.AuthActionTypes.RegistrationSuccess),
            map(action => action.payload),
            tap(({ user }) => this.toastService.showToast(`User ${user.email} registered`).subscribe()),
        ), { dispatch: false }
    );

    onPasswordChange = createEffect(() =>
        this.actions$.pipe(
            ofType(PasswordManagementActions.PasswordManagementActionTypes.ChangePasswordSuccess),
            tap(() => this.toastService.showToast(`Password changed succesfully`).subscribe()),
            map(() => RouterActions.routerGo({ path: ['/home'] }))
        )
    );

    showLoader$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthActions.AuthActionTypes.CredentialsLogin,
                AuthActions.AuthActionTypes.FacebookLogin,
                AuthActions.AuthActionTypes.GoogleLogin,
                AuthActions.AuthActionTypes.CredentialsRegistration,
                PasswordManagementActions.PasswordManagementActionTypes.ChangePasswordRequest,
                PasswordManagementActions.PasswordManagementActionTypes.ResetPasswordRequest,
            ),
            tap(() => this.ngxService.start())
        ), { dispatch: false }
    )

    hideLoader$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthActions.AuthActionTypes.Authenticated,
                AuthActions.AuthActionTypes.NotAuthenticated,
                AuthActions.AuthActionTypes.AuthError,
                AuthActions.AuthActionTypes.RegistrationSuccess,
                PasswordManagementActions.PasswordManagementActionTypes.ResetPasswordRequestError,
                PasswordManagementActions.PasswordManagementActionTypes.ResetPasswordRequestSuccess,
                PasswordManagementActions.PasswordManagementActionTypes.ChangePasswordError,
                PasswordManagementActions.PasswordManagementActionTypes.ChangePasswordSuccess,
            ),
            tap(() => this.ngxService.stop())
        ), { dispatch: false }
    )

    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private toastService: ToastService,
        private alertService: AlertService,
        private ngxService: NgxUiLoaderService
    ) { }
}