import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { AuthActions } from '@xtream/firebase-ngrx-user-management';

import * as fromAuth from './auth.reducer';
import * as fromCalendar from './calendar.reducer';
import * as fromLesson from './lesson.reducer';
import * as fromStep from './step.reducer';

export interface AppState {
  router: fromRouter.RouterReducerState<any>,
  calendar: fromCalendar.CalendarState;
  lesson: fromLesson.LessonState;
  step: fromStep.StepState;
  auth: fromAuth.AuthState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: fromRouter.routerReducer,
  calendar: fromCalendar.reducer,
  lesson: fromLesson.reducer,
  step: fromStep.reducer,
  auth: fromAuth.reducer
};

// export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
//   return localStorageSync({ keys: ['calendar', 'lesson'], rehydrate: true })(reducer);
// }

export const metaReducers: MetaReducer<AppState>[] = [clearState];

export const selectCalendarState = createFeatureSelector<fromCalendar.CalendarState>('calendar');
export const selectLessonState = createFeatureSelector<fromLesson.LessonState>('lesson');
export const selectStepState = createFeatureSelector<fromStep.StepState>('step');
export const selectAuthState = createFeatureSelector<fromAuth.AuthState>('auth');
export const selectRouter = createFeatureSelector<AppState, fromRouter.RouterReducerState<any>>('router');

export function clearState(reducer) {
  return (state, action) => {
    if (action.type === AuthActions.AuthActionTypes.NotAuthenticated) {
      state = undefined;
    }

    return reducer(state, action);
  };
}