import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './commons/guards/auth.guard';
import { LessonResolver } from './commons/resolvers/lesson.resolver';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lesson',
    loadChildren: () => import('./pages/lesson/lesson.module').then(m => m.LessonPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then(m => m.PasswordResetPageModule)
  },
  {
    path: 'password-change',
    loadChildren: () => import('./pages/password-change/password-change.module').then(m => m.PasswordChangePageModule)
  },
  {
    path: 'lesson-view',
    loadChildren: () => import('./pages/lesson-view/lesson-view.module').then(m => m.LessonViewPageModule),
  },
  {
    path: 'lesson-view/:id',
    loadChildren: () => import('./pages/lesson-view/lesson-view.module').then(m => m.LessonViewPageModule),
    data: {
      viewOnly: true
    },
    resolve: {
      lesson: LessonResolver
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  providers: [LessonResolver, AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
