import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { getUser } from '@xtream/firebase-ngrx-user-management';
import * as firebase from 'firebase/app';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { from } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ModalService } from 'src/app/commons/services/modal.service';
import { StepSelectorComponent } from 'src/app/pages/lesson/step-selector/step-selector.component';

import { LESSON_DURATION } from '../../commons/helpers/defaults';
import { LessonDTO } from '../../commons/models/lesson';
import { AlertService } from '../../commons/services/alert.service';
import { ToastService } from '../../commons/services/toast.service';
import * as LessonActions from '../actions/lesson.actions';
import * as RouterActions from '../actions/router.actions';
import { AppState } from '../reducers';

@Injectable()
export class LessonEffects {

    error$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.saveLessonFailed),
            tap(({ error }) => {
                if (error) {
                    this.toastService.showError('Error', error);
                }
            })
        ), { dispatch: false }
    );

    addLesson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.addLesson),
            map((action) => {
                let lesson = {
                    start: firebase.firestore.Timestamp.fromDate(action.date),
                    minutes: LESSON_DURATION,
                    steps: null
                };
                return LessonActions.editLesson({ lesson });
            })

        )
    );

    loadLesson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.loadLesson),
            switchMap(({ id }) =>
                this.db.collection<LessonDTO>("lessons").doc(id).snapshotChanges().pipe(
                    map(action => {
                        const game = action.payload.data() as LessonDTO;
                        const id = action.payload.id;
                        return { id, ...game };
                    })
                )
            ),
            map((lesson) => LessonActions.loadLessonCompleted({ lesson }))
        )
    );

    showLesson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.showLesson),
            map(() => RouterActions.routerGo({ path: ['/lesson-view'] }))
        )
    );

    editLesson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.editLesson),
            map(() => RouterActions.routerGo({ path: ['/lesson'] }))
        )
    );

    shareLesson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.shareLesson),
            tap(({ lesson }) => {
                const lessonUrl = `https://yoga-planner-40ec9.web.app/lesson-view/${lesson.id}`;
                this.socialSharing.share(lessonUrl);
            })
        ), { dispatch: false }
    );

    saveLesson$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.saveLesson),
            withLatestFrom(this.store$.pipe(select(getUser))),
            switchMap(([{ lesson }, user]) => {
                const lessonsCollection = this.db.collection<LessonDTO>('lessons');
                const savingLesson = { ...lesson, uid: user.uid };
                return from(lesson.id ?
                    lessonsCollection.doc(savingLesson.id).update(savingLesson) :
                    lessonsCollection.add(savingLesson)).pipe(map(() => savingLesson))
            }),
            map((lesson) => LessonActions.saveLessonCompleted({ lesson }))
        )
    )

    onSaveCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.saveLessonCompleted),
            tap(() => this.toastService.showToast(`Lesson updated`).subscribe())
        ), { dispatch: false }
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.deleteLesson),
            switchMap(({ lesson }) => {
                return this.alertService.showConfirm('Confirm', `Are you sure you want to delete lesson?`)
                    .pipe(map((confirm) => confirm ? LessonActions.deleteLessonConfirmed({ lesson }) : LessonActions.deleteLessonCancelled()))
            })
        )
    );

    deleteConfirmed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.deleteLessonConfirmed),
            switchMap(({ lesson }) => {
                return from(this.db.collection<LessonDTO>('lessons').doc(lesson.id).delete()).pipe(
                    map(() => LessonActions.deleteLessonCompleted({ lesson }))
                );
            })
        )
    )

    onDeleteCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.deleteLessonCompleted),
            tap(() => this.toastService.showToast(`Lesson deleted`).subscribe()),
            // map(() => RouterActions.routerBack())
        ), { dispatch: false }
    );

    selectNewStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.selectNewStep),
            tap(() => this.modalService.showModal({ component: StepSelectorComponent }).subscribe())
        ), { dispatch: false }
    );

    addStep$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.addStep),
            tap(({ step }) => this.toastService.showToast(`${step.name} added`).subscribe())
        ), { dispatch: false }
    );

    closeStepSelector$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LessonActions.closeStepSelector),
            tap(() => this.modalService.dismissModal())
        ), { dispatch: false }
    );

    showLoader$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                LessonActions.saveLesson,
                LessonActions.deleteLessonConfirmed
            ),
            tap(() => this.ngxService.start())
        ), { dispatch: false }
    )

    hideLoader$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                LessonActions.saveLessonCompleted,
                LessonActions.deleteLessonCompleted
            ),
            tap(() => this.ngxService.stop())
        ), { dispatch: false }
    )

    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private toastService: ToastService,
        private alertService: AlertService,
        private db: AngularFirestore,
        private modalService: ModalService,
        private ngxService: NgxUiLoaderService,
        private socialSharing: SocialSharing
    ) { }
}