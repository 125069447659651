import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ComponentRef, ModalOptions, OverlayEventDetail } from '@ionic/core';
import { from, Observable } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';

@Injectable()
//mrosetti - This service cannot be provided in root because it needs to know modal Component. It needs to be provided in each module using thi service.
export class ModalService {
  constructor(private modalController: ModalController) { }

  showModal(opts: ModalOptions<ComponentRef>): Observable<OverlayEventDetail> {
    return from(this.modalController.create(opts)).pipe(
      tap(modal => modal.present()),
      flatMap(modal => {
        return from(modal.onDidDismiss());
      })
    );
  }

  dismissModal(params?: any) {
    this.modalController.dismiss(params);
  }
}
