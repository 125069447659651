import { Action, createReducer, on } from '@ngrx/store';

import { Asana, Prana } from '../../commons/models/asana';
import * as StepActions from '../actions/step.actions';

export interface StepState {
    asanas: Asana[],
    pranas: Prana[],
}

const initialState: StepState = {
    asanas: null,
    pranas: null,
};

const stepState = createReducer(
    initialState,
    on(StepActions.loadAsanasCompleted, (state, { asanas }) => {
        return { ...state, asanas };
    }),
    on(StepActions.loadPranasCompleted, (state, { pranas }) => {
        return { ...state, pranas };
    }),

);

export function reducer(state: StepState | undefined, action: Action) {
    return stepState(state, action);
}

