import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { from, Observable } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class ToastService {
  constructor(private toastController: ToastController) { }

  showToast(
    message: string,
    closeText: string = null,
    duration: number = 2500,
    position: "top" | "bottom" | "middle" = "bottom"
  ): Observable<boolean> {
    return from(
      this.toastController.create({
        message: message,
        showCloseButton: closeText != null,
        closeButtonText: closeText,
        position: position,
        duration: duration
      })
    ).pipe(
      tap(toast => toast.present()),
      flatMap(toast => {
        return from(toast.onDidDismiss()).pipe(
          map(result => result.role != "timeout")
        );
      })
    );
  }

  showError(
    message: string,
    error: any,
    position: "top" | "bottom" | "middle" = "middle"
  ) {
    let totalMessage: string = `${message}`;
    if (error) {
      totalMessage += `: ${this.extractErrorMessage(error)}`;
    }
    this.toastController
      .create({
        message: totalMessage,
        showCloseButton: false,
        duration: 5000,
        position: position,
        cssClass: "error"
      })
      .then(toast => {
        toast.present();
      });
  }

  private extractErrorMessage(error: any): string {
    if (error instanceof HttpErrorResponse) {
      return error.error ? error.error.message : "Generic HTTP error";
    }
    if (typeof error === "string") {
      return error;
    }

    return JSON.stringify(error);
  }
}
