import * as dayjs from 'dayjs';
import * as firebase from 'firebase/app';

import { LessonDTO } from '../models/lesson';
import { LESSON_DURATION, LESSON_START_HOUR } from './defaults';

export function isLessonInDay(lesson: LessonDTO, day: Date) {
    return dayjs(timestampToDate(lesson.start)).isSame(day, "day");
}

export function getNextStartDate(dailyLessons: LessonDTO[], day: Date): Date {
    return dailyLessons && dailyLessons.length
        ? dayjs(timestampToDate(sortLessons(dailyLessons)[dailyLessons.length - 1].start)).add(LESSON_DURATION, "minute").toDate()
        : dayjs(day).hour(LESSON_START_HOUR).minute(0).toDate();
}

export function sortLessons(lessons: LessonDTO[]): LessonDTO[] {
    return lessons.sort((l1, l2) => {
        return l1.start.toMillis() - l2.start.toMillis();
    })
}

export function timestampToDate(timestamp: firebase.firestore.Timestamp): Date {
    return new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
}

export function getStartOfMonth(day: Date): Date {
    return dayjs(day).startOf('month').toDate();
}

export function getEndOfMonth(day: Date): Date {
    return dayjs(day).endOf('month').toDate();
}

