import { AbstractControl } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as firebase from 'firebase/app';
import { timestampToDate } from 'src/app/commons/helpers/utils';

import { Asana, Prana } from './asana';

export interface LessonDTO {
    id?: string;
    name?: string;
    start: firebase.firestore.Timestamp;
    minutes: number;
    steps: string;
    note?: string;
    uid?: string;
}

export class Lesson {
    id?: string;
    name?: string;
    start: Date;
    minutes: number;
    steps: Array<Asana | Prana>;
    note?: string;

    constructor(source: LessonDTO) {
        if (source) {
            this.id = source.id;
            this.name = source.name;
            if (source.start) {
                this.start = timestampToDate(source.start)
            }
            this.minutes = source.minutes;
            this.note = source.note;
            if (source.steps) {
                this.steps = JSON.parse(source.steps);
            }
        }
    }

    toDTO(): LessonDTO {
        let dto: LessonDTO = {
            id: this.id ? this.id : null,
            name: this.name ? this.name : null,
            start: this.start ? firebase.firestore.Timestamp.fromDate(dayjs(this.start).toDate()) : null,
            minutes: this.minutes ? this.minutes : null,
            note: this.note ? this.note : null,
            steps: this.steps ? JSON.stringify(this.steps) : null
        }
        return dto;
    }

    isInInterval(start: Date, end: Date): boolean {
        return this.start >= dayjs(start).startOf('day').toDate() && this.end <= dayjs(end).endOf('day').toDate();
    }

    get end(): Date {
        return dayjs(this.start).add(this.minutes, 'minute').toDate();
    }

    static fromFormGroup(formGroup: AbstractControl, original?: Lesson): Lesson {
        const value = formGroup.value;
        let lesson: Lesson = new Lesson(null);

        lesson.name = value.name;
        lesson.note = value.note;
        lesson.start = value.start;
        lesson.minutes = dayjs(value.end).diff(value.start, "minute");
        lesson.steps = value.steps;
        if (original) {
            lesson.id = original.id;
        }
        return lesson;

    }
}