import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Actions, EffectsModule, ofType } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthActions, FirebaseNgrxUserManagementModule, ɵe } from '@xtream/firebase-ngrx-user-management';
import {
    NgxUiLoaderConfig,
    NgxUiLoaderHttpModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    SPINNER,
} from 'ngx-ui-loader';
import { take } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalService } from './commons/services/modal.service';
import { StepSelectorComponent } from './pages/lesson/step-selector/step-selector.component';
import { AuthEffects } from './store/effects/auth.effects';
import { CalendarEffects } from './store/effects/calendar.effects';
import { LessonEffects } from './store/effects/lesson.effects';
import { RouterEffects } from './store/effects/router.effects';
import { StepEffects } from './store/effects/step.effects';
import { AppState, metaReducers, reducers } from './store/reducers';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: "#d35776",
  fgsType: SPINNER.threeBounce,
  hasProgressBar: false,
  overlayColor: "rgba(130,130,130,0.7)",
  logoSize: 100,
  logoUrl: "assets/images/logo_nobg.png",
};


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function initApplication(translateService: TranslateService, store: Store<AppState>, actions$: Actions): Function {
  return () => new Promise(resolve => {
    translateService.setDefaultLang('it-IT');
    store.dispatch(new AuthActions.GetUser());
    actions$.pipe(ofType(AuthActions.AuthActionTypes.Authenticated, AuthActions.AuthActionTypes.NotAuthenticated), take(1)).subscribe(() => resolve(true))
  })
}

@NgModule({
  declarations: [AppComponent, StepSelectorComponent],
  entryComponents: [StepSelectorComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true
    }),
    NgxUiLoaderRouterModule.forRoot({
      showForeground: true
    }),
    FirebaseNgrxUserManagementModule,
    AngularFireModule.initializeApp(environment.firebase, 'Yoga planner'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule, StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      },
    }), StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AuthEffects, StepEffects, LessonEffects, CalendarEffects, RouterEffects]),
    StoreRouterConnectingModule.forRoot()],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ɵe,
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      deps: [TranslateService, Store, Actions],
      multi: true
    },
    ModalService,
    SocialSharing
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
