import { createSelector } from '@ngrx/store';

import { isLessonInDay, sortLessons } from '../../commons/helpers/utils';
import { Lesson } from '../../commons/models/lesson';
import { CalendarState } from '../reducers/calendar.reducer';
import { selectCalendarState } from './../reducers';

export const getLessons = createSelector(
    selectCalendarState,
    (state: CalendarState) => state.lessons ? state.lessons.map(l => new Lesson(l)) : []
)

export const getDailyLessons = createSelector(
    selectCalendarState,
    (state: CalendarState) => {
        if (state.selectedDay && state.lessons && state.lessons.length > 0) {
            return sortLessons(state.lessons.filter(lesson => isLessonInDay(lesson, state.selectedDay))).map(l => new Lesson(l))
        }
        return [];
    }
)

export const getSelectedDay = createSelector(
    selectCalendarState,
    (state: CalendarState) => state.selectedDay
)

export const getCurrentRange = createSelector(
    selectCalendarState,
    (state: CalendarState) => { return { startDate: state.startDate, endDate: state.endDate } }
)

