import { Action, createReducer, on } from '@ngrx/store';

import { Lesson, LessonDTO } from '../../commons/models/lesson';
import * as CalendarActions from '../actions/calendar.actions';
import * as LessonActions from '../actions/lesson.actions';


export interface CalendarState {
    startDate: Date;
    endDate: Date;
    selectedDay: Date;
    lessons: LessonDTO[];
};

const initialState: CalendarState = {
    startDate: null,
    endDate: null,
    selectedDay: null,
    lessons: null
};

const calendarReducer = createReducer(
    initialState,
    on(CalendarActions.loadLessonsCompleted, (state, { lessons }) => {
        return { ...state, lessons };
    }),
    on(CalendarActions.selectDay, (state, { day }) => {
        return { ...state, selectedDay: day };
    }),
    on(CalendarActions.intervalChanged, (state, { startDate, endDate }) => {
        return { ...state, startDate, endDate };
    }),
    on(LessonActions.saveLessonCompleted, (state, { lesson }) => {
        const oldEntity = state.lessons.find(l => l.id === lesson.id);
        let newLessons = [...state.lessons];
        if (oldEntity) {
            const index = state.lessons.indexOf(oldEntity);
            newLessons = [
                ...state.lessons.slice(0, index),
                lesson,
                ...state.lessons.slice(index + 1)
            ];
        } else {
            if (new Lesson(lesson).isInInterval(state.startDate, state.endDate)) {
                newLessons.push(lesson);
            }
        }
        return { ...state, lessons: newLessons };
    }),
);

export function reducer(state: CalendarState | undefined, action: Action) {
    return calendarReducer(state, action);
}


